<template>
    <div class="p-4">
        <top-search></top-search>

        <div v-if="rider" class="w-full my-12 px-4">
            <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1 ">
                            <h3 class="font-semibold text-lg">Riders</h3>
                        </div>
                    </div>
                </div>
                
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                        <h2 class="accordion-header mb-0" id="flush-headingOne">
                            <button class="accordion-button relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-brown-100 border-0 rounded-none transition focus:outline-none focus:bg-brown-100" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Bio-Data
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body py-4 px-5 md:flex md:justify-around gap-8">
                                <div class="">
                                    <img class="h-64 w-64" :src="'https://raadaa-test.s3.af-south-1.amazonaws.com/'+rider.passport" alt="rider passport">
                                </div>

                                <div class="flex justify-center">
                                    <ul class="bg-white rounded-lg border border-gray-200 w-96 text-gray-900 text-left">
                                        <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">
                                            <h3 class="mb-2 font-semibold text-gray-600">Name: <span class="font-thin"> {{ rider.user.first_name }} {{ rider.user.last_name }} </span></h3>
                                        </li>
                                        <li class="px-6 py-2 border-b border-gray-200 w-full">
                                            <h3 class="mb-2 font-semibold text-gray-600">Contact Email: <span class="font-thin"> {{ rider.user.email }} </span></h3>
                                        </li>
                                        <li class="px-6 py-2 border-b border-gray-200 w-full">
                                            <h3 class="mb-2 font-semibold text-gray-600">Contact Number: <span class="font-thin">{{ rider.user.phone }}</span> </h3>
                                        </li>
                                        <li class="px-6 py-2 border-b border-gray-200 w-full">
                                            <h3 class="mb-2 font-semibold text-gray-600">Business Address: <span class="font-thin">{{ rider.business_address }}</span> </h3>
                                        </li>
                                        <li class="px-6 py-2 border-b border-gray-200 w-full">
                                            <h3 class="mb-2 font-semibold text-gray-600">City: <span class="font-thin">{{ rider.city.name }}</span> </h3>
                                        </li>
                                        <li class="px-6 py-2 border-b border-gray-200 w-full">
                                            <h3 class="mb-2 font-semibold text-gray-600">Properties: <span v-for="(item, index) in rider.properties" :key="index" class="inline-flex flex-wrap font-thin uppercase mx-2">{{ item }},</span> </h3>
                                        </li>
                                        <li class="px-6 py-2 w-full rounded-b-lg">
                                            <h3 class="mb-2 font-semibold text-gray-600">Account Status: <span class="font-thin uppercase">{{ rider.status }}</span> </h3>
                                        </li>
                                    </ul>
                                </div>

                                <!-- <div class="px-10 mt-10">
                                    <div class="bg-white rounded-md max-w-4xl mx-auto p-4 space-y-4 shadow-lg"> -->
                                        <!-- <h3 class="mb-2 font-semibold text-gray-600">Name: <span class="font-thin"> {{ rider.user.first_name }} {{ rider.user.last_name }} </span></h3>
                                        <h3 class="mb-2 font-semibold text-gray-600">Email: <span class="font-thin"> {{ rider.user.email }} </span></h3>
                                        <h3 class="border-t mb-2 pt-3 font-semibold text-gray-600">Business Address: <span class="font-thin">{{ rider.business_address }}</span> </h3>
                                        <h3 class="border-t mb-2 pt-3 font-semibold text-gray-600">City: <span class="font-thin">{{ rider.city.name }}</span> </h3>
                                        <h3 class="border-t mb-2 pt-3 font-semibold text-gray-600">Status: <span class="font-thin uppercase">{{ rider.status }}</span> </h3> -->
                                        <!-- <div class="flex justify-end space-x-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 cursor-pointer" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-indigo-600 cursor-pointer"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500 cursor-pointer"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </div> -->
                                    <!-- </div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                        <h2 class="accordion-header mb-0" id="flush-headingTwo">
                            <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Rider's Credentials
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body py-4 px-5">
                                <section class="overflow-hidden text-gray-700 ">
                                    <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-8">
                                        <div class="flex flex-wrap -m-1 md:-m-2">
                                            <div class="flex flex-wrap w-1/3">
                                                <div class="w-full p-1 md:p-2 mb-4">
                                                    <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                                                        :src="'https://raadaa-test.s3.af-south-1.amazonaws.com/'+ rider.national_id">
                                                    <label for="national_id" class="">National ID</label>
                                                </div>
                                            </div>
                                            <div class="flex flex-wrap w-1/3">
                                                <div class="w-full p-1 md:p-2 mb-4">
                                                    <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                                                        :src="'https://raadaa-test.s3.af-south-1.amazonaws.com/'+ rider.driver_license" >
                                                    <label for="national_id">Driver's License</label>
                                                </div>
                                            </div>
                                            <div class="flex flex-wrap w-1/3">
                                                <div class="w-full p-1 md:p-2 mb-4">
                                                    <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                                                        :src="'https://raadaa-test.s3.af-south-1.amazonaws.com/'+ rider.address_prove" >
                                                    <label for="national_id">Prove of Address</label>
                                                </div>
                                            </div>
                                            
                                            <!-- <div class="flex flex-wrap w-1/3">
                                                <div class="w-full p-1 md:p-2">
                                                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                                                    src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp">
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    
                    <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                        <h2 class="accordion-header mb-0" id="flush-headingThree">
                            <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                Others
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <!-- <div class="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div> -->
                            <!-- <img class="h-64" :src="'https://raadaa-test.s3.af-south-1.amazonaws.com/'+rider.image" alt="image"> -->
                            <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-8">
                                <div class="flex flex-wrap -m-1 md:-m-2">
                                    <div class="flex flex-wrap w-1/2">
                                        <div class="w-full p-1 md:p-2 mb-4">
                                            <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                                                :src="'https://raadaa-test.s3.af-south-1.amazonaws.com/'+ rider.image">
                                            <label for="rider_avatar" class="">Rider Avatar</label>
                                        </div>
                                    </div> 
                                    <div class="flex flex-col w-1/2 p-8">
                                        <p class="leading-4 font-medium py-2 bg-gray-100 capitalize">Assigned vendors</p>
                                        <div class="" v-for="(assigned, index) in rider.assign_rider" :key="index">
                                            {{ assigned.vendor.business_name }}
                                        </div>
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full h-96 bg-red-100">
                <ul v-for="(vendor, index) in vendors" :key="index">
                    <li>{{ vendor.business_name }} <span>{{ vendor.business_address }}</span> 
                        <button @click="vendor_id = vendor.id, assignRider()" class="px-4 bg-brown-500 text-white rounded-md">Assign</button></li>
                </ul>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'

export default {
    name: 'Rider_Details',
    components: {
        TopSearch,
        SquareGrid
    },
    data() {
        return {
            vendor_id: ''
        }
    },
    computed: {
        ...mapGetters({
            rider: 'ridersModule/getRider',
            vendors: 'ridersModule/getVendorLists',
        })
    },
    methods: {
        ...mapActions({assign: 'ridersModule/assign'}),
        assignRider() {
            const data = {
                rider_id: this.rider.id,
                vendor_id: this.vendor_id,
            }
            this.$store.dispatch('ridersModule/assign', data)
        }
    }
}
</script>